var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-nav',{staticClass:"m-0",attrs:{"card-header":"","pills":""}},[_c('b-nav-item',{attrs:{"to":{ name: 'debt-solution-ci-message-cost' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[_vm.getRoute('debt-solution-ci-message-cost')?'router-link-active':'',
        'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v(" GENERAL ")]),_c('b-nav-item',{attrs:{"to":{ name: 'debt-solution-manage-courts' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[ _vm.getRoute('debt-solution-manage-courts')?'router-link-active':'',
        'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v("COURT MANAGEMENT ")]),_c('b-nav-item',{attrs:{"to":{ name: 'debt-solution-documents-config' },"exact":"","exact-active-class":"sub-tab-nav","link-classes":[ _vm.getRoute('debt-solution-documents-config')?'router-link-active':'',
        'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v("DOCUMENTS CONFIG ")])],1),_c('b-card',{staticClass:"border-top-info border-3 border-table-radius px-0",attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name,ref:"routerView"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }