<template>
  <div >
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'debt-solution-ci-message-cost' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[getRoute('debt-solution-ci-message-cost')?'router-link-active':'',
          'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
      >
        GENERAL
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'debt-solution-manage-courts' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[ getRoute('debt-solution-manage-courts')?'router-link-active':'',
          'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]" 
        >COURT MANAGEMENT
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'debt-solution-documents-config' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[ getRoute('debt-solution-documents-config')?'router-link-active':'',
          'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
        >DOCUMENTS CONFIG
      </b-nav-item>
    </b-nav>
    <b-card
      no-body 
      class="border-top-info border-3 border-table-radius px-0"
    >
      <router-view ref="routerView" :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
export default {
  methods:{
    getRoute(route){
      return route == this.$route.matched[3].name;
    }
  }
};
</script>